<template>
  <div v-if="articlesToShow && articlesToShow.length > 0">
    <div v-if="listView" class="-mt-8 md:-mt-24">
      <template
        v-for="(article, index) in articlesToShow"
        :key="'article' + index"
      >
        <div class="mt-4">
          <ArticleTeaser
            :article="article"
            :index="index"
            :always-image-small="true"
            :lazy="true"
            :preload="true"
            :always-teaser="true"
            placement="main"
            :loadMore="loadMore"
            :same-size="sameSize"
          />

          <CommonDivider :class="'my-3 md:my-5'" />
        </div>
      </template>
      <div v-if="pending" class="flex justify-center">
        <CommonLoading />
      </div>
      <div class="flex justify-center">
        <CommonButton @click.native="loadMore">
          {{ $t('ShowMore') }}
        </CommonButton>
      </div>
    </div>
    <template v-if="swiper">
      <LayoutSwipeList>
        <SwiperSlide v-for="(article, index) in articlesToShow" :key="index">
          <ArticleTeaser
            :article="article"
            :index="index"
            :lazy="true"
            :preload="true"
            :slider="true"
            :sameSize="true"
            small
          />
        </SwiperSlide>
      </LayoutSwipeList>
    </template>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
import type { ContentArticle } from '~/typesManual/content_api/article'

const articles = ref<ContentArticle[]>([])
withDefaults(
  defineProps<{
    count?: number
    listView?: boolean
    swiper?: boolean
    loadMore?: boolean
    sameSize?: boolean
  }>(),
  { count: 50, listView: false, swiper: false, loadMore: true, sameSize: false }
)

const {
  data: allArticles,
  loadMore,
  pending,
} = await useArticles({
  offset: 0,
  count: 50,
  alreadyFetchedArticles: articles.value,
  type: 'instream',
})

const articlesToShow = computed(() => {
  if (!allArticles.value) return
  return allArticles.value?.filter((article) => {
    return article.TextToSpeechFile !== ''
  })
})
</script>
